import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { url } from '../config';
import RichEditor from './RichEditor';
// import JoditEditor from "jodit-react";
import MultiSelect from './MultiSelect';
import Main from './SortableImages/Main';
// import ColorPicker from "./ColorPicker";

const country = [
  'Mazowieckie',
  'Świętokrzyskie',
  'Wielkopolskie',
  'Lubelskie',
  'Warmińsko-Mazurskie',
  'Podlaskie',
  'Pomorskie',
  'Łódzkie',
  'Dolnośląskie',
  'Zachodniopomorskie',
  'Podkarpackie',
  'Kujawsko-Pomorskie',
  'Śląskie',
  'Małopolskie',
  'Opolskie',
  'Lubuskie',
];

const additionals = [
  'Żywe kwiaty',
  'Sztuczne kwiaty',
  'Tablice rejestracyjne „Młoda Para”',
  'Personalizowane tablice rejestracyjne',
  'Wstążki',
  'Balony',
  'Parasol na wypadek deszczu',
  'Kosz na prezenty dla gości',
  'Nagrywanie GoPro',
  'Nagrywanie wnętrza samochodu',
  'Nagrywanie samochodu z zewnątrz',
  'Dekoracje w cenie',
  'Tablice rejestracyjne w cenie',
];

const CarForm = () => {
  // const editor = useRef(null);
  const [content, setContent] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const photoInputRef = useRef(null);
  const videoInputRef = useRef(null);

  const [carData, setCarData] = useState({
    title: '',
    owner: {
      name: '',
      phone: '',
      email: '',
      instagram: '',
      facebook: '',
      website: '',
    },
    photos: [],
    videos: [],
    yearOfProduction: '',
    color: '',
    interior: '',
    typeOfCar: '',
    numberOfSeats: '',
    additionalAmenities: [],
    rentalPrice: '',
    location: '',
    rentalDuration: '',
    specialOptionsForWedding: [],
    description: '',
    isVerified: false,
  });

  useEffect(() => {
    if (carData.description) {
      setContent(carData.description);
    }
  }, [carData.description]);
  const handleEditorChange = (newContent) => {
    setContent(newContent);
    carData.description = newContent;
  };
  // const [selectedLocations, setSelectedLocations] = useState([]);
  const [photoPreviews, setPhotoPreviews] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const setLocation = (locations) => {
    setCarData((prev) => ({ ...prev, location: locations }));
  };
  useEffect(() => {
    const savedFormData = localStorage.getItem('carFormData');
    if (savedFormData) {
      setCarData(JSON.parse(savedFormData));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('carFormData', JSON.stringify(carData));
  }, [carData]);

  const handleAdditionalsChange = (newAmmenities) => {
    const amenitiesArray = newAmmenities.split(',').map((item) => item.trim());
    setCarData((prev) => ({ ...prev, additionalAmenities: amenitiesArray }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('owner.')) {
      const ownerField = name.split('.')[1];
      setCarData((prev) => ({
        ...prev,
        owner: { ...prev.owner, [ownerField]: value },
      }));
    } else {
      setCarData((prev) => ({ ...prev, [name]: value }));
    }
  };
  const capitalize = (str) => {
    return str
      ? str
          .trim()
          .toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase())
      : '';
  };

  const MAX_PHOTOS = 5;
  const MAX_PHOTO_SIZE_MB = 5;
  const MAX_VIDEOS = 3;
  const MAX_VIDEO_SIZE_MB = 60;

  const handleFileChange = (e, type) => {
    const files = Array.from(e.target.files);
    let isValid = true;
    let errorMessage = '';

    if (type === 'photos') {
      if (true) {
        // Create an array of objects with file and its preview URL
        const photosWithPreviews = files.map((file) => ({
          file,
          preview: URL.createObjectURL(file),
        }));

        setCarData((prev) => ({
          ...prev,
          photos: photosWithPreviews.map(({ file }) => file), // Store only files in carData
        }));

        setPhotoPreviews(photosWithPreviews.map(({ preview }) => preview)); // Store previews separately
      } else {
        alert(errorMessage);
      }
    } else if (type === 'videos') {
      if (files.length > MAX_VIDEOS) {
        isValid = false;
        errorMessage = `You can upload a maximum of ${MAX_VIDEOS} videos.`;
      } else {
        // No size check for videos, assuming it's not necessary as per your request
      }

      if (isValid) {
        // Create an array of objects with file and its preview URL
        const videosWithPreviews = files.map((file) => ({
          file,
          preview: URL.createObjectURL(file), // Video preview URL
        }));

        // Send the video files as a buffer to the backend
        // Example: Create a FormData and append each file
        const formData = new FormData();
        videosWithPreviews.forEach(({ file }) => {
          formData.append('videos', file);
        });

        // Send the FormData to the backend (assuming you have an API for this)
        fetch('/api/upload-videos', {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            console.log('Videos uploaded successfully', data);
            // Handle the backend response
          })
          .catch((error) => {
            console.error('Error uploading videos:', error);
          });

        // Store videos and their previews
        setCarData((prev) => ({
          ...prev,
          videos: videosWithPreviews.map(({ file }) => file), // Store only files in carData
        }));

        setVideoPreviews(videosWithPreviews.map(({ preview }) => preview)); // Store previews separately
      } else {
        alert(errorMessage);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found.');
      }

      const formData = new FormData();
      formData.append('title', carData.title || '');
      formData.append('owner[name]', capitalize(carData.owner.name) || '');
      formData.append('owner[phone]', carData.owner.phone.trim() || '');
      formData.append('owner[email]', carData.owner.email.trim() || '');
      formData.append(
        'owner[instagram]',
        carData.owner.instagram?.trim() || ''
      );
      formData.append('owner[facebook]', carData.owner.facebook?.trim() || '');
      formData.append('owner[website]', carData.owner.website?.trim() || '');
      formData.append('yearOfProduction', carData.yearOfProduction);
      formData.append('color', capitalize(carData.color) || '');
      formData.append('typeOfCar', capitalize(carData.typeOfCar) || '');
      formData.append('interior', capitalize(carData.interior) || '');
      formData.append('numberOfSeats', carData.numberOfSeats.trim() || '');
      formData.append('rentalPrice', carData.rentalPrice.trim() || '');
      formData.append('location', carData.location || '');
      formData.append('rentalDuration', carData.rentalDuration.trim() || '');

      carData.additionalAmenities.forEach((amenity) => {
        formData.append('additionalAmenities', capitalize(amenity));
      });

      carData.specialOptionsForWedding.forEach((option) => {
        formData.append('specialOptionsForWedding', capitalize(option));
      });

      formData.append('description', content);
      formData.append('isVerified', carData.isVerified);

      // Append photos and videos as they are
      carData.photos.forEach((photo) => {
        formData.append('photos', photo);
      });

      carData.videos.forEach((video) => {
        formData.append('videos', video);
      });

      // Log FormData (for debugging purposes)
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }

      // Send data to the backend
      const response = await fetch(`${url}/api/car/create-car`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        const message = errorData.errors
          ? errorData.errors[0].msg
          : errorData.message || 'An error occurred.';
        setError(message);
        return;
      }

      // const result = await response.json();
      // console.log("Car created successfully:", result);
      localStorage.removeItem('carFormData');
      navigate('/lista-samochodów');
    } catch (error) {
      console.error('Fetch error:', error);
      setError('An error occurred while submitting the form.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      encType='multipart/form-data'
      onSubmit={handleSubmit}
      className='max-w-lg mx-auto p-4 bg-white shadow-md rounded'
    >
      <h2 className='text-2xl font-bold mb-4'>Dodaj Nowy Samochód</h2>
      {error && <p className='text-red-700 text-sm'>{error}</p>}
      <div className='mb-4'>
        <label className='block text-sm font-semibold mb-2' htmlFor='title'>
          Marka i Model Samochodu
        </label>
        <input
          type='text'
          id='title'
          name='title'
          value={carData.title}
          onChange={handleChange}
          required
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>

      <div className='mb-4'>
        <h3 className='font-semibold'>Informacje o Właścicielu Samochodu</h3>
        <label
          className='block text-sm font-semibold mb-2'
          htmlFor='owner.name'
        >
          Imię
        </label>
        <input
          type='text'
          name='owner.name'
          value={carData.owner.name}
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>

      <div className='mb-4'>
        <label
          className='block text-sm font-semibold mb-2'
          htmlFor='owner.phone'
        >
          Numer Telefonu
        </label>
        <input
          type='text'
          name='owner.phone'
          value={carData.owner.phone}
          onChange={handleChange}
          minLength={9}
          maxLength={15}
          required
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>

      <div className='mb-4'>
        <label
          className='block text-sm font-semibold mb-2'
          htmlFor='owner.email'
        >
          Adres e-mail
        </label>
        <input
          type='email'
          required
          name='owner.email'
          value={carData.owner.email}
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>
      <div className='mb-4'>
        <label className='block text-sm font-semibold mb-2' htmlFor='facebook'>
          Facebook (opcjonalnie)
        </label>
        <input
          type='text'
          name='owner.facebook'
          value={carData.owner.facebook}
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>
      <div className='mb-4'>
        <label className='block text-sm font-semibold mb-2' htmlFor='instagram'>
          Instagram (opcjonalnie)
        </label>
        <input
          type='text'
          name='owner.instagram'
          value={carData.owner.instagram}
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>
      <div className='mb-4'>
        <label className='block text-sm font-semibold mb-2' htmlFor='website'>
          Strona internetowa (opcjonalnie)
        </label>
        <input
          type='text'
          name='owner.website'
          value={carData.owner.website}
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>
      <div className='mb-4'>
        <label className='block text-sm font-semibold mb-2' htmlFor='photos'>
          Zdjęcia
        </label>
        <input
          //   ref={photoInputRef}
          type='file'
          name='photos'
          required
          multiple
          accept='image/jpeg, image/png, image/webp, image/jpg'
          onChange={(e) => handleFileChange(e, 'photos')}
          className='w-full p-2 border border-gray-300 rounded'
        />

        <div className='bg-slate-100'>
          <Main
            images={photoPreviews}
            onSortEnd={(newOrder) => {
              setPhotoPreviews(newOrder);

              // Rearrange carData.photos based on newOrder
              setCarData((prev) => {
                const reorderedPhotos = newOrder.map((preview) => {
                  const index = photoPreviews.indexOf(preview); // Find the index of the preview
                  return prev.photos[index]; // Use the index to get the correct file
                });

                return { ...prev, photos: reorderedPhotos };
              });
            }}
          />
        </div>
      </div>

      <div className='mb-4'>
        <label className='block text-sm font-semibold mb-2' htmlFor='videos'>
          Filmy
        </label>
        <input
          ref={videoInputRef}
          type='file'
          name='videos'
          multiple
          accept='video/*'
          onChange={(e) => handleFileChange(e, 'videos')}
          className='w-full p-2 border border-gray-300 rounded'
        />
        <div className='mt-4 grid grid-cols-1 gap-4'>
          {videoPreviews.map((src, index) => (
            <video
              key={index}
              controls
              className='w-full h-32 object-cover rounded'
            >
              <source src={src} type='video/mp4' />
            </video>
          ))}
        </div>
      </div>
      <div className='mb-4'>
        <label
          className='block text-sm font-semibold mb-2'
          htmlFor='yearOfProduction'
        >
          Rok Produkcji
        </label>
        <input
          type='number'
          min='1'
          name='yearOfProduction'
          value={carData.yearOfProduction}
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>

      <div className='mb-4'>
        <label className='block text-sm font-semibold mb-2' htmlFor='color'>
          Kolor
        </label>
        <input
          type='text'
          name='color'
          value={carData.color}
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>
      <div className='mb-4'>
        <label
          className='block text-sm font-semibold mb-2'
          htmlFor='typesOfCar'
        >
          Typ Samochodu
        </label>
        <input
          type='text'
          name='typeOfCar'
          value={carData.typeOfCar}
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>

      <div className='mb-4'>
        <label className='block text-sm font-semibold mb-2' htmlFor='interior'>
          Wnętrze
        </label>
        <input
          type='text'
          name='interior'
          value={carData.interior}
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>

      <div className='mb-4'>
        <label
          className='block text-sm font-semibold mb-2'
          htmlFor='numberOfSeats'
        >
          Liczba miejsc
        </label>
        <input
          min='1'
          type='number'
          name='numberOfSeats'
          value={carData.numberOfSeats}
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>

      <div className='mb-4'>
        <label
          className='block text-sm font-semibold mb-2'
          htmlFor='additionalAmenities'
        >
          Dodatkowe udogodnienia (oddzielone przecinkami)
        </label>
        <MultiSelect options={additionals} setValue={handleAdditionalsChange} />
      </div>

      <div className='mb-4'>
        <label
          className='block text-sm font-semibold mb-2'
          htmlFor='rentalPrice'
        >
          Cena Wynajmu
        </label>
        <input
          type='number'
          min='1'
          name='rentalPrice'
          value={carData.rentalPrice}
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>
      <div className='mb-4'>
        <label className='block text-sm font-semibold mb-2' htmlFor='location'>
          Lokalizacja
        </label>
        <MultiSelect options={country} setValue={setLocation} />
      </div>
      <div className='mb-4'>
        <label
          className='block text-sm font-semibold mb-2'
          htmlFor='rentalDuration'
        >
          Czas wynajmu (opcjonalnie)
        </label>
        <input
          type='text'
          name='rentalDuration'
          value={carData.rentalDuration}
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>
      <div className='mb-4'>
        <label
          className='block text-sm font-semibold mb-2'
          htmlFor='specialOptionsForWedding'
        >
          Specjalnie dodatki (oddzielone przecinkami)
        </label>
        <input
          type='text'
          name='specialOptionsForWedding'
          value={carData.specialOptionsForWedding.join(', ')}
          onChange={(e) =>
            setCarData({
              ...carData,
              specialOptionsForWedding: e.target.value.split(', '),
            })
          }
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>

      <div className='mb-4'>
        <label
          className='block text-sm font-semibold mb-2'
          htmlFor='description'
        >
          Opis
        </label>
        <RichEditor
          name='description'
          required
          value={content}
          onChange={handleEditorChange}
          className='w-full border border-gray-300 rounded'
        />
      </div>

      <div className='mb-4'>
        <label className='inline-flex items-center'>
          <input
            type='checkbox'
            name='isVerified'
            checked={carData.isVerified}
            onChange={() =>
              setCarData({ ...carData, isVerified: !carData.isVerified })
            }
            className='mr-2'
          />
          <span className='text-sm'>Zweryfikowany</span>
        </label>
      </div>

      <button
        type='submit'
        disabled={loading}
        className={`w-full p-2 text-white rounded ${
          loading ? 'bg-gray-400' : 'bg-blue-600'
        }`}
      >
        {loading ? 'Submitting...' : 'Dodaj Samochód'}
      </button>
    </form>
  );
};

export default CarForm;
