import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "./List.css";
import { url } from "../../config";

const DecorationLists = () => {
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const limit = 10;

  const fetchDecors = async (page = 1, fetchAll = false) => {
    try {
      const limitParam = fetchAll ? 0 : limit;
      const response = await fetch(
        `${url}/api/decor/decorations-lists?page=${page}&limit=${limitParam}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setList(data.decors);
        setTotalPages(data.pagination.totalPages);
        setTotalCount(data.pagination.totalDecors);
        setError("");
      } else {
        setList([]);
        setError("No decors found.");
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setError("An error occurred while fetching decors.");
    }
  };

  useEffect(() => {
    fetchDecors(currentPage);
  }, [currentPage]);

  const handleEditDecor = (decorId) => {
    navigate(`/edit-dekor/${decorId}`);
  };

  const removeDecor = async (decorId) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this decor?"
    );
    if (!confirmation) return;

    try {
      const token = localStorage.getItem("authToken");

      if (!token) {
        console.error("No token found. User might not be logged in.");
        return;
      }

      const response = await fetch(
        `${url}/api/decor/remove-decorations/${decorId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setList((prevList) =>
          prevList.filter((decor) => decor._id !== decorId)
        );
        fetchDecors();
      } else {
        console.error("Error deleting decor:", data.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error deleting decor:", error);
    }
  };

  return (
    <div className="list add flex-col">
      <p className="text-xl font-bold">Lista Wszystkich Dekoracji</p>
      {error && <p className="text-red-500">{error}</p>}
      <p>Wszystkie Dekoracje: {totalCount || "Loading..."}</p>
      <div className="overflow-x-auto">
        <div className="list-table mb-8 min-w-full">
          <div className="list-table-format title">
            <b className="flex-1">Zdjęcie</b>
            <b className="flex-1"> Tytuł</b>
            <b className="flex-1">Numer telefonu</b>
            <b className="flex-1">Typ Dekoracji</b>
            <b className="flex-1">Akcje</b>
          </div>
          {list.map((item, index) => (
            <div key={index} className="list-table-format">
              {item.photos && item.photos.length > 0 ? (
                <img src={item.photos[0]} alt="Decoration" />
              ) : (
                <p>No image available</p>
              )}
              <p className="truncate">{item.title}</p>
              <p>{item.owner.phone}</p>
              <p>{item.typeOfDecoration}</p>
              <span className="flex space-x-2">
                <p
                  onClick={() => handleEditDecor(item._id)}
                  className="cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="text-blue-500 hover:text-blue-700"
                  />
                </p>
                <p
                  onClick={() => removeDecor(item._id)}
                  className="cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="text-red-500 hover:text-red-700"
                  />
                </p>
              </span>
            </div>
          ))}
        </div>
      </div>
      {list.length > 0 && (
        <div className="flex justify-between items-center mt-4 mb-4">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className={`lg:px-4 lg:py-2 px-2 py-2 lg:text-[16px] text-[10px] bg-blue-500 text-white rounded ${
              currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            Poprzednia
          </button>
          <span>{`Strona ${currentPage} z ${totalPages}`}</span>
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className={`lg:px-4 lg:py-2 px-2 py-2 lg:text-[16px] text-[10px] bg-blue-500 text-white rounded ${
              currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            Następna
          </button>
        </div>
      )}
    </div>
  );
};

export default DecorationLists;
