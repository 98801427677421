import { useEffect, useState, useRef } from "react";
import { url } from "../config";
// import JoditEditor from "jodit-react";
// import ColorPicker from "./ColorPicker";
import { useNavigate, useParams } from "react-router-dom";
import RichEditor from "./RichEditor";
import MultiSelect from "./MultiSelect";
import Main from "./SortableImages/Main";

const country = [
  "Mazowieckie",
  "Świętokrzyskie",
  "Wielkopolskie",
  "Lubelskie",
  "Warmińsko-Mazurskie",
  "Podlaskie",
  "Pomorskie",
  "Łódzkie",
  "Dolnośląskie",
  "Zachodniopomorskie",
  "Podkarpackie",
  "Kujawsko-Pomorskie",
  "Śląskie",
  "Małopolskie",
  "Opolskie",
  "Lubuskie",
];

const additionals = [
  "Żywe kwiaty",
  "Sztuczne kwiaty",
  "Tablice rejestracyjne „Młoda Para”",
  "Personalizowane tablice rejestracyjne",
  "Wstążki",
  "Balony",
  "Parasol na wypadek deszczu",
  "Kosz na prezenty dla gości",
  "Nagrywanie GoPro",
  "Nagrywanie wnętrza samochodu",
  "Nagrywanie samochodu z zewnątrz",
  "Dekoracje w cenie",
  "Tablice rejestracyjne w cenie",
];

const EditCarForm = () => {
  // const [selectedLocations, setSelectedLocations] = useState([]);
  const { id: carId } = useParams();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const navigate = useNavigate();
  // const editor = useRef(null);
  const [carData, setCarData] = useState({
    title: "",
    owner: {
      name: "",
      phone: "",
      email: "",
      facebook: "",
      instagram: "",
      website: "",
    },
    photos: [],
    videos: [],
    yearOfProduction: "",
    color: "",
    typeOfCar: "",
    interior: "",
    numberOfSeats: "",
    additionalAmenities: [],
    rentalPrice: "",
    location: "",
    rentalDuration: "",
    specialOptionsForWedding: [],
    description: "",
    isVerified: false,
  });

  useEffect(() => {
    const fetchCarData = async () => {
      try {
        const response = await fetch(`${url}/api/car/car/${carId}`);
        if (!response.ok) {
          throw new Error("Failed to fetch car data");
        }
        const carData = await response.json();
        setCarData({
          ...carData.car,
          isVerified: carData.car.isVerified || false,
        });
        if (carData && carData.car) {
          setCarData(carData.car);
          setPhotoPreviews(carData.car.photos || []);
          setVideoPreviews(carData.car.videos || []);
        }
      } catch (error) {
        setError(error.message);
      }
    };

    if (carId) {
      fetchCarData();
    }
  }, [carId]);
  const setLocation = (locations) => {
    setCarData((prev) => ({ ...prev, location: locations }));
  };

  useEffect(() => {
    if (carData.photos.length) {
      const updatedPhotos = carData.photos.map((photo) => {
        // Check if the photo URL is relative and does not already have a host
        return photo.startsWith("http")
          ? photo
          : `http://localhost:5000${photo}`;
      });

      console.log(updatedPhotos);
      setPhotoPreviews(updatedPhotos);
    }
    if (carData.description) {
      setContent(carData.description);
    }
  }, [carData.description]);

  const handleEditorChange = (newContent) => {
    setContent(newContent);
    setCarData((prev) => ({ ...prev, description: newContent }));
  };

  const [error, setError] = useState("");
  const [photoPreviews, setPhotoPreviews] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const photoInputRef = useRef(null);
  const videoInputRef = useRef(null);

  const prevAdditionals = Array.isArray(carData.additionalAmenities)
    ? carData.additionalAmenities
        .filter((amenity) => amenity.trim() !== "") // Filter out empty strings
        .map((amenity) => ({
          value: amenity.trim(),
          label: amenity.trim(),
        }))
    : typeof carData.additionalAmenities === "string"
    ? carData.additionalAmenities
        .split(",") // Split the string into individual values
        .map((amenity) => amenity.trim()) // Trim whitespace
        .filter((amenity) => amenity !== "") // Remove empty strings
        .map((amenity) => ({
          value: amenity,
          label: amenity,
        }))
    : [];

  const previousLocations = Array.isArray(carData.location)
    ? carData.location
        .filter((loc) => loc.trim() !== "") // Filter out empty strings
        .map((loc) => ({
          value: loc,
          label: loc,
        }))
    : typeof carData.location === "string"
    ? carData.location
        .split(",")
        .map((loc) => loc.trim())
        .filter((loc) => loc !== "") // Filter out empty strings
        .map((loc) => ({
          value: loc,
          label: loc,
        }))
    : [];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("owner.")) {
      setCarData({
        ...carData,
        owner: {
          ...carData.owner,
          [name.split(".")[1]]: value,
        },
      });
    } else {
      setCarData({ ...carData, [name]: value });
    }
  };

  const handleAdditionals = (newAmmenities) => {
    const amenitiesArray = newAmmenities.split(",").map((item) => item.trim());
    setCarData((prev) => ({ ...prev, additionalAmenities: amenitiesArray }));
  };

  const handleFileChange = (e, type) => {
    const files = Array.from(e.target.files);
    if (type === "photos") {
      setCarData({ ...carData, photos: files });
      setPhotoPreviews(files.map((file) => URL.createObjectURL(file)));
    } else if (type === "videos") {
      setCarData({ ...carData, videos: files });
      setVideoPreviews(files.map((file) => URL.createObjectURL(file)));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem("authToken");

      if (!token) {
        throw new Error("No authentication token found.");
      }

      const capitalize = (str) => {
        return str
          ? str.toLowerCase().replace(/^\w|\s\w/g, (c) => c.toUpperCase())
          : "";
      };
      const sanitizedCarData = {
        ...carData,
        title: carData.title?.trim() || "",
        owner: {
          ...carData.owner,
          name: capitalize(carData.owner?.name?.trim() || ""),
          phone: carData.owner?.phone?.trim() || "",
          email: carData.owner?.email?.trim() || "",
          instagram: carData.owner?.instagram?.trim() || "",
          facebook: carData.owner?.facebook?.trim() || "",
          website: carData.owner?.website?.trim() || "",
        },
        yearOfProduction: carData.yearOfProduction
          ? carData.yearOfProduction.toString().trim()
          : "",
        color: capitalize(carData.color?.trim() || ""),
        typeOfCar: capitalize(carData.typeOfCar?.trim() || ""),
        interior: capitalize(carData.interior?.trim() || ""),
        numberOfSeats: carData.numberOfSeats
          ? carData.numberOfSeats.toString().trim()
          : "",
        rentalPrice: carData.rentalPrice
          ? carData.rentalPrice.toString().trim()
          : "",
        location: carData.location?.trim() || "",
        rentalDuration: capitalize(carData.rentalDuration?.trim() || ""),
        additionalAmenities:
          carData.additionalAmenities?.map((item) =>
            capitalize(item?.trim())
          ) || [],
        specialOptionsForWedding:
          carData.specialOptionsForWedding?.map((item) =>
            capitalize(item?.trim())
          ) || [],
        description: content?.trim() || "",
        isVerified: carData.isVerified,
        photos: carData.photos || [],
        videos: carData.videos || [],
      };

      // Prepare FormData with sanitized and capitalized data
      const formData = new FormData();
      formData.append("title", sanitizedCarData.title);
      formData.append("owner[name]", sanitizedCarData.owner.name);
      formData.append("owner[phone]", sanitizedCarData.owner.phone);
      formData.append("owner[email]", sanitizedCarData.owner.email);
      formData.append("owner[instagram]", sanitizedCarData.owner.instagram);
      formData.append("owner[facebook]", sanitizedCarData.owner.facebook);
      formData.append("owner[website]", sanitizedCarData.owner.website);
      formData.append("yearOfProduction", sanitizedCarData.yearOfProduction);
      formData.append("color", sanitizedCarData.color);
      formData.append("typeOfCar", sanitizedCarData.typeOfCar);
      formData.append("interior", sanitizedCarData.interior);
      formData.append("numberOfSeats", sanitizedCarData.numberOfSeats);
      formData.append("rentalPrice", sanitizedCarData.rentalPrice);
      formData.append("location", sanitizedCarData.location);
      formData.append("rentalDuration", sanitizedCarData.rentalDuration);
      formData.append(
        "additionalAmenities",
        sanitizedCarData.additionalAmenities.join(",")
      );
      formData.append(
        "specialOptionsForWedding",
        sanitizedCarData.specialOptionsForWedding.join(",")
      );
      formData.append("description", sanitizedCarData.description);
      formData.append("isVerified", sanitizedCarData.isVerified);

      sanitizedCarData.photos.forEach((photo) => {
        formData.append("photos", photo);
      });

      sanitizedCarData.videos.forEach((video) => {
        formData.append("videos", video);
      });

      // Log the sanitized data for debugging
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }

      const response = await fetch(`${url}/api/car/edit-car/${carId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error Data:", errorData);

        if (errorData.message === "Not Authorized") {
          setError("Session expired. Please log in again.");
          localStorage.removeItem("authToken");
          navigate("/login");
          return;
        }

        const message = errorData.errors
          ? errorData.errors[0].msg
          : errorData.message || "An error occurred.";
        setError(message);
        return;
      }

      navigate("/lista-samochodów");
    } catch (error) {
      console.error("Fetch error:", error);
      setError("An error occurred while submitting the form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      encType="multipart/form-data"
      onSubmit={handleSubmit}
      className="max-w-lg mx-auto p-4 bg-white shadow-md rounded"
    >
      {error && <p className="text-red-700 text-sm">{error}</p>}
      <h2 className="text-2xl font-bold mb-4">Aktualizuj Samochód</h2>
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2" htmlFor="title">
          Marka i Model Samochodu
        </label>
        <input
          type="text"
          id="title"
          name="title"
          value={carData.title}
          onChange={handleChange}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="mb-4">
        <h3 className="font-semibold">Informacje o Właścicielu Samochodu</h3>
        <label
          className="block text-sm font-semibold mb-2"
          htmlFor="owner.name"
        >
          Imię
        </label>
        <input
          type="text"
          name="owner.name"
          value={carData.owner.name}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-sm font-semibold mb-2"
          htmlFor="owner.phone"
        >
          Numer Telefonu
        </label>
        <input
          type="text"
          required
          name="owner.phone"
          value={carData.owner.phone}
          onChange={handleChange}
          minLength={9}
          maxLength={15}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-sm font-semibold mb-2"
          htmlFor="owner.email"
        >
          Adres e-mail
        </label>
        <input
          type="email"
          required
          name="owner.email"
          value={carData.owner.email}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2" htmlFor="facebook">
          Facebook (opcjonalnie)
        </label>
        <input
          type="text"
          name="owner.facebook"
          value={carData.owner.facebook}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2" htmlFor="instagram">
          Instagram (opcjonalnie)
        </label>
        <input
          type="text"
          name="owner.instagram"
          value={carData.owner.instagram}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2" htmlFor="website">
          Strona internetowa (opcjonalnie)
        </label>
        <input
          type="text"
          name="owner.website"
          value={carData.owner.website}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2" htmlFor="photos">
          Zdjęcia
        </label>
        <input
          ref={photoInputRef}
          type="file"
          name="photos"
          multiple
          accept="image/*"
          onChange={(e) => handleFileChange(e, "photos")}
          className="w-full p-2 border border-gray-300 rounded"
        />
        <Main
          images={photoPreviews}
          onSortEnd={(newOrder) => {
            setPhotoPreviews(newOrder);

            // Rearrange carData.photos based on newOrder
            setCarData((prev) => {
              const reorderedPhotos = newOrder.map((preview) => {
                const index = photoPreviews.indexOf(preview); // Find the index of the preview
                return prev.photos[index]; // Use the index to get the correct file
              });

              return { ...prev, photos: reorderedPhotos };
            });
          }}
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2" htmlFor="videos">
          Filmy
        </label>
        <input
          ref={videoInputRef}
          type="file"
          name="videos"
          multiple
          accept="video/*"
          onChange={(e) => handleFileChange(e, "videos")}
          className="w-full p-2 border border-gray-300 rounded"
        />
        <div className="mt-4 grid grid-cols-1 gap-4">
          {videoPreviews.map((src, index) => (
            <video
              key={index}
              controls
              className="w-full h-32 object-cover rounded"
            >
              <source src={src} type="video/mp4" />
            </video>
          ))}
        </div>
      </div>
      <div className="mb-4">
        <label
          className="block text-sm font-semibold mb-2"
          htmlFor="yearOfProduction"
        >
          Rok Produkcji
        </label>
        <input
          type="number"
          name="yearOfProduction"
          value={carData.yearOfProduction}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2" htmlFor="color">
          Kolor
        </label>
        {/* <ColorPicker color={formData.color} onChange={handleColorChange} /> */}
        <input
          type="text"
          name="color"
          value={carData.color}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-sm font-semibold mb-2"
          htmlFor="typesOfCar"
        >
          Typ Samochodu
        </label>
        <input
          type="text"
          name="typeOfCar"
          value={carData.typeOfCar}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2" htmlFor="interior">
          Wnętrze
        </label>
        <input
          type="text"
          name="interior"
          value={carData.interior}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-sm font-semibold mb-2"
          htmlFor="numberOfSeats"
        >
          Liczba miejsc
        </label>
        <input
          type="number"
          name="numberOfSeats"
          value={carData.numberOfSeats}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-sm font-semibold mb-2"
          htmlFor="additionalAmenities"
        >
          Dodatkowe udogodnienia (oddzielone przecinkami)
        </label>
        <MultiSelect
          options={additionals}
          setValue={handleAdditionals}
          value={prevAdditionals}
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-sm font-semibold mb-2"
          htmlFor="rentalPrice"
        >
          Cena Wynajmu
        </label>
        <input
          type="number"
          min="1"
          name="rentalPrice"
          value={carData.rentalPrice}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2" htmlFor="location">
          Lokalizacja
        </label>
        <MultiSelect
          options={country}
          setValue={setLocation}
          value={previousLocations}
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-sm font-semibold mb-2"
          htmlFor="rentalDuration"
        >
          Czas wynajmu (opcjonalnie)
        </label>
        <input
          type="text"
          name="rentalDuration"
          value={carData.rentalDuration}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-sm font-semibold mb-2"
          htmlFor="specialOptionsForWedding"
        >
          Specjalnie dodatki (oddzielone przecinkami)
        </label>
        <input
          type="text"
          name="specialOptionsForWedding"
          value={carData.specialOptionsForWedding.join(", ")}
          onChange={(e) =>
            setCarData({
              ...carData,
              specialOptionsForWedding: e.target.value.split(", "),
            })
          }
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-sm font-semibold mb-2"
          htmlFor="description"
        >
          Opis
        </label>
        {/* <JoditEditor
          ref={editor}
          value={content}
          tabIndex={1}
          onChange={handleEditorChange}
          className="w-full border border-gray-300 rounded"
        /> */}
        <RichEditor
          name="description"
          required
          value={content}
          onChange={handleEditorChange}
          className="w-full border border-gray-300 rounded"
        />
        {/* <textarea
          name="description"
          required
          value={carData.description}
          onChange={handleChange}
          className="w-full border border-gray-300 rounded"
        /> */}
      </div>

      <div className="mb-4">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            name="isVerified"
            checked={carData.isVerified}
            onChange={() =>
              setCarData({ ...carData, isVerified: !carData.isVerified })
            }
            className="mr-2"
          />
          <span className="text-sm">Zweryfikowany</span>
        </label>
      </div>

      <button
        type="submit"
        disabled={loading}
        className={`w-full p-2 text-white rounded ${
          loading ? "bg-gray-400" : "bg-blue-600"
        }`}
      >
        {loading ? "Updating..." : "Aktualizuj samochód"}
      </button>
    </form>
  );
};

export default EditCarForm;
